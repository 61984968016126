import React, { useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faFacebook, faFacebookMessenger, faYoutube } from '@fortawesome/free-brands-svg-icons'

import SubscribeForm from './SubscribeForm'
import NAVIGATION from '../data/navigation'

import './Footer.scss'
import Link from './Link'
import { LogoWhite } from './Logo'
import LocaleContext from './LocaleContext'


const Footer = () => {
  const SOCIAL_LINKS = [
    {
      name: 'LinkedIn',
      link: 'https://www.linkedin.com/company/myhr/',
      icon: (<FontAwesomeIcon icon={faLinkedin} />)
    },
    {
      name: 'Messenger',
      link: 'https://m.me/MyHRworks',
      icon: (<FontAwesomeIcon icon={faFacebookMessenger} />)
    },
    {
      name: 'FaceBook',
      link: 'https://www.facebook.com/MyHRworks',
      icon: (<FontAwesomeIcon icon={faFacebook} />)
    },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/channel/UC1k46KqK-GQqVxIBbZsrofQ',
      icon: (<FontAwesomeIcon icon={faYoutube} />)
    },
  ]
  const locale = useContext(LocaleContext)

  
  // Group navigation items to display in columns.
  const navLinks = NAVIGATION[locale.value]
  const navColumns = ['en-NZ', 'en-AU'].includes(locale.value) ?
    [
      [navLinks[0]],
      [navLinks[1],navLinks[2]],
      [navLinks[3]],
      [navLinks[4]]
    ]
    : null

  return (
    <footer className="Footer">
      <Container>
        <Row>
          <Col xl={4}>
            <div className="Footer__newsletter mx-auto ml-xl-0 mb-6 pr-xl-4">
              <div className="Footer__logo mb-5">
                <LogoWhite />
              </div>
              <div className="Footer__title">Join our mailing list</div>
              <p>Join thousands of people just like you who want to stay up to date with the latest in HR.</p>
              <SubscribeForm textColor="cyan" themeColor="cyan"/>
              <div className="my-5">
                {SOCIAL_LINKS.map(({name, link, icon}) => (
                  <a className="Footer__social-icon" href={link} title={name} key={name}>
                    {icon}
                  </a>
                ))}
              </div>
            </div>
          </Col>

          <Col xl={8}>
            {navColumns &&
              <div className="Footer__nav">
                <Row className="mb-5">
                  {navColumns.map((col, index) => (
                    <Col xs={6} md={3} key={index}>
                      {col.map((navGroup, navGroupIndex) => (
                        <div key={navGroupIndex}>
                          <div className="Footer__title">{navGroup.label}</div>
                          <ul className="list-unstyled">
                            {navGroup.children.map((navItem, index) =>
                              <li key={index}>
                                <Link
                                  href={navItem.href}
                                  localePrefix={navItem.localePrefix}
                                >
                                  {navItem.label}
                                </Link>
                              </li>
                            )}
                            {navGroup.label=="Platform" &&
                              <li>
                                <Link
                                  href="/integrations"
                                  localePrefix="true"
                                >
                                  Integrations
                                </Link>
                              </li>
                            }
                          </ul>
                        </div>
                      ))}

                    </Col>
                  ))}
                </Row>
              </div>
            }
            <Row className="mb-5">
              <Col md={6}>
                <div className="Footer__title">New Zealand</div>
                <Row>
                  <Col xs={6}>
                    <address>
                      <p className="mb-3">
                        <b>Auckland</b><br/>
                        Harbourside Business Park<br/>
                        485C Rosebank Road<br/>
                        Auckland 1026
                      </p>
                    </address>
                  </Col>
                  <Col xs={6}>
                    <address>
                      <p className="mb-3">
                        <b>Christchurch</b><br/>
                        4 Ash Street<br/>
                        Christchurch Central City<br/>
                        Christchurch 8011<br/>
                      </p>
                    </address>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} md={3}>
                <div className="Footer__title">Australia</div>
                <address>
                  <p className="mb-3">
                    <b>Sydney</b><br/>
                    MyHR Australia<br/>
                    64 York St, Sydney, NSW 2000
                  </p>
                </address>
              </Col>
              <Col xs={6} md={3}>
                <div className="Footer__title">Canada</div>
                <address>
                  <p className="mb-3">
                    <b>British Columbia</b><br/>
                    108 - 1870 Dowad Avenue<br/>
                    Squamish<br/>
                    British Columbia, V8B1C4  
                  </p>
                </address>
              </Col>
            </Row>

          </Col>
        </Row>

        <div className="Footer__bottom">
          <Row className="font-weight-bold py-2">
            <Col md={6} className="my-3 text-center text-md-right order-md-12">
              <a className="Footer__bottom-nav-link" href="https://app.myhr.works/privacy">Privacy Policy</a>
              <a className="Footer__bottom-nav-link" href="/contact">Contact Us</a>

              <div className="Footer__bottom-nav-link country-switcher language-switcher">
                {locale.country}
                <div className="country-switcher__pop-up">
                  <div className="country-switcher__list">
                    <a data-language="en-nz" href="/nz">New Zealand</a>
                    <a data-language="en-au" href="/au">Australia</a>
                    <a data-language="en-au" href="/ca">Canada</a>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="my-3 text-center text-md-left order-md-1">
              <span>© MyHR All Rights Reserved</span>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  )
}


export default Footer
